



































import { CouponItem } from '@/Type/index'
import { Vue,Component,Prop,Emit } from "vue-property-decorator"

interface SelComponentType<T=string>{
    BackCard:T;
    BackNotCard:T;
    ActiveIcon:T;
    NotIcon:T;

    handleClick():void;
    handleFilterTime(str:T):void;
}

@Component({ name:"SelComponent" })
export default class SelComponent extends Vue implements SelComponentType{
    BackCard = require("$icon/Coupon/CardActive.png");
    BackNotCard = require("$icon/Coupon/CardNotActive.png");
    ActiveIcon = require("$icon/Coupon/CardMark.png");
    NotIcon = require("$icon/Coupon/CardNotMark.png");

    @Prop(Object)
    data!:CouponItem

    get GetData():CouponItem{
        return this.data
    }

    @Prop(Number)
    index!:number;

    get GetIndex():number{
        return this.index
    }

    handleClick(){
        this.PullClick()
    }

    handleFilterTime(str:string):string{
       return str.split(" ")[0]
    }

    @Emit("PullClick")
    PullClick(){
        return {
            data:this.GetData,
            index:this.GetIndex
        }
    }
}
