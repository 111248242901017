























import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import Stroage from "../../util/Storage"
import List from '../../components/ShareComponent/PullDownUp.vue'
import SelComponet from "../../components/CouponComponent/SelComponent.vue"
import { CouponList } from '../../Api/Basics/index'
import { CouponItem } from '../../Type/index'
import { FilterList,ToggleActive,IsPageNo } from '@/util/filter'
import { CouponStore } from "@/store/modules/Basics/Coupon"
import { Vue,Component,Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface UpDataType<T>{
   pageNo:number;
   pageSize:number;
   types:T;
   status:T|number
}

interface SelCouponType<T=string>{
    userId:T|number;
    token:T;
    loadingShow:boolean;
    pullDown:boolean;
    List:CouponItem[]
    stopUpDown:boolean;
    upDown:boolean;
    UpData:UpDataType<T>

    handleReftchClick(data:any):void;
    handlePullDown(data?:any):void;
    handleUpDown(data?:any):void;
    handleUpData(data?:boolean):void;
    handleError(data:string):void;
}

@Component({ name:"SelCoupon",components:{ SelComponet,List,HeadTop } })
export default class SelCoupon extends ZoomPage implements SelCouponType{
   loadingShow = true
   userId: string|number = ""
   token = ""
   pullDown = false
   List:CouponItem[] = []
   stopUpDown = false;
   upDown = false
   UpData:UpDataType<string> = {
       pageNo: 1,
       pageSize: 100,
       types: "0",
       status: 0
   }

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.UpData.types = this.$route.query.type && this.$route.query.type as string || "0"

       if( CouponStore.GetSelType === this.$route.query.type ){
           if( CouponStore.GetSelList.length ){
               this.loadingShow = false
               this.UpData.pageNo = IsPageNo( CouponStore.GetSelList.length,this.UpData.pageSize )
               this.List = CouponStore.GetSelList
           }else{
               CouponStore.SetSelType( this.$route.query.type )
               this.handleUpData()
           }
       }else{
           CouponStore.SetSelType( this.$route.query.type as string || "" )
           this.handleUpData()
       }
   }

   handleReftchClick(data:any){
       this.List = ToggleActive( this.List,data.index )
       CouponStore.SetSelCoupon( data.data )
       if( this.List[data.index] && this.List[data.index]["active"] ){
           this.$router.go(-1)
       }else{
           CouponStore.SetSelCoupon( {} )
       }
   }

   handlePullDown(data?: any): void {
       this.pullDown = true
       this.UpData.pageNo = 1
       CouponList({
           userId:this.userId,
           token:this.token
       },this.UpData).then((res:any)=>{
           this.pullDown = false
           console.log( res )
           if ( res.message.code === "200" ) {
               if ( res.data.length < this.UpData.pageSize ) {
                   this.stopUpDown = true
               }else{
                   this.stopUpDown = false
                   this.UpData.pageNo += 1
               }
               this.List = FilterList(res.data)
           }else{
               this.handleError(res.message.msg)
           }
       })
   }

   handleUpDown(data?: any): void {
       if ( this.stopUpDown )return;
       this.upDown = true
       this.UpData.pageNo += 1
       this.handleUpData()
   }

   handleUpData(bool?: boolean): void {
       CouponList({
           userId:this.userId,
           token:this.token
       },this.UpData).then((res:any)=>{
           console.log( res )
           this.loadingShow = false
           this.upDown = false
           if ( res.message.code === "200" ) {
               if ( res.data.length < this.UpData.pageSize ) {
                   this.stopUpDown = true
               }
               let List :any[] = []
               if ( bool ) {
                   this.List = FilterList(res.data)
               }else{
                   this.List = this.List.concat( FilterList(res.data) )
               }
           }else{
               this.handleError(res.message.msg)
           }
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Watch("List")
   handleList(newVal:any){
       CouponStore.SetSelList( newVal )
   }
}
